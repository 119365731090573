
/*========================
	12. FOOTER
=========================*/
footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a {
    color: #b0b0b0;
}
footer p,
footer strong,
footer b,
footer {
    color: rgba(255,255,255,0.8);
}
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
    color: rgba(255,255,255,0.8);
}

footer .btn-link,
footer a,
footer p a {
    color: rgba(255,255,255,0.6);
}
footer a:active,
footer a:focus,
footer a:hover {
    color: rgba(255,255,255,0.6);
}
footer {
	background-color: $black-100;
}
footer .footer-container {
    max-width: 1140px;
    margin: 0 auto;
}
footer .footer-top{
	font-weight: 400;
}
footer .footer-bottom{
	font-size: 14px;
	color:rgba(255,255,255,0.6);
	font-weight: 400;
}
footer .footer-title{
	font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
footer .footer_widget ul li a{
    font-weight: 400;
}
footer .footer-bottom p{
	font-size: 14px;
    color: rgba(255,255,255,0.6);
    font-weight: 400;
	line-height:24px;
	text-align: center;
}
footer .ft-social-bx a{
    border-radius: 40px;
    font-size: 16px;
    height: 40px;
    width: 40px;
    line-height: 40px;
	border: 1px solid white;
	margin: 0 1em 0.5em 0;
}
footer p {
    margin-bottom: 10px;
}
footer p,
footer li {
    font-size: 16px;
    line-height: 32px;
}
footer#footer {
    background-position: center;
    background-size: cover;
}
footer p {
    line-height: 32px;
    margin-bottom: 15px;
	color: rgba(255,255,255,0.6);
	font-weight: 400;
}
footer .footer_widget ul {
    list-style: none;
    margin-top: -10px;
	padding: 0;
}
footer .footer_widget div {
	line-height: 25px;
}
footer img {
    width: 220px;
    height: auto;
}
.footer-top {
    background-position: center;
    padding: 80px 0 40px;
}
.footer-top .widget {
    margin-bottom: 30px;
}
.footer-bottom {
    background-color: $black-100;
    padding: 25px 0;
    color: #b0b0b0;
	border-top:1px solid rgba(255,255,255,0.05);
}
.footer-bottom ul {
    margin: 0;
}
.footer-title{
	margin-bottom: 20px;
	color: #fff;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 20px;
	position: relative;
}
.footer_widget ul li a{
	padding: 5px 0;
    display: block;
    font-weight: 400;
}
.ft-social-bx{
	margin-bottom:0;
}
.ft-social-bx a{
	margin-right: 0;
	color: #fff;
	width: 45px;
	height: 45px;
	line-height: 45px;
	padding: 0;
	text-align: center;
	font-size: 14px;
}
.ft-social-bx a i{
	margin:0;
}
.list-2 ul{
	margin:0;
	padding:0;
	list-style:none;
	display:table;
	margin-top:-8px;
	margin-bottom:-8px;
}
.list-2 ul li{
	width:50%;
	float:left;
}
.list-2 ul li a{
    font-size: 16px;
    font-weight: 400;
    padding: 8px 0;
    display: block;
	line-height: 22px;
}
.footer_talkus p{
	margin-top: -10px;
}
.footer-innner-btm{
	display:flex;
	justify-content: space-between;
	border-top:1px solid rgba(255,255,255,0.1);
	padding: 25px 0;
}
.footer-innner-btm p{
	color:#fff;
	opacity:0.5;
	font-weight:400;
	font-size:14px;
	margin:0;
}
.footer-info-bx .ttr-tilte{
    font-weight: 500;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
}
.footer-info{
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding-bottom: 40px;
    margin-top: -35px;
}
.btn-long{
	padding-left:40px;
	padding-right:40px;
}
.footer-info-bx p{
	font-size: 14px;
    line-height: 22px;
}
.link-inline{
	margin:0;
	padding:0;
	list-style:none;
}
.link-inline li{
	display:inline-block;
    line-height: 24px;	
}
.link-inline li a{
    color: rgba(255,255,255,0.6);
    font-weight: 400;
    padding-left: 23px;
	font-size: 14px;	
}
.widget_info ul{
	margin:0;
	padding:0;
	list-style:none;
    margin-top: -10px;	
}
.widget_info ul li{
    color: rgba(255,255,255,0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 5px 0;	
	margin: 0 3px;
}
.widget_info ul li strong{
	font-weight: 300;
    margin-right: 5px;
	color:rgba(255,255,255,0.6);
}


.bg-dark-gray{
	background-color:#3744a8;
}
