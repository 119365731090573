/*=================================
	4. MENU BAR
=================================*/
.menu-bar {
    background: #FFFFFF;
    width: 100%;
    position: relative;
}
.menu-bar .container{
	position: relative;
}
.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin:28px 0 30px 15px;
    padding: 0;
	float:right;
}
.navbar-toggler span {
    background: #000;
}
.menu-links {
    padding: 0;
}
.menu-links .nav {
    float: right;
}
.menu-links .nav i {
    font-size: 9px;
    margin-left: 3px;
    margin-top: -3px;
    vertical-align: middle;
}
.menu-links .nav > li {
    margin: 0px;
    font-weight: 500;
    position: relative;
}
.menu-links .nav > li > a {
    border-radius: 0px;
    color: #020d26;
    font-size: 17px;
    padding: 28px 10px 27px 10px;
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    position: relative;
}
.menu-links .nav > li > a:hover {
    background-color: transparent;
    color: #efbb20;
}
.menu-links .nav > li > a:active,
.menu-links .nav > li > a:focus {
    background-color: transparent;
}
.menu-links .nav > li.active > a,
.menu-links .nav > li.current-menu-item > a {
    background-color: transparent;
    color: #EFBB20;
}
.menu-links .nav > li:hover > a {
    color: #EFBB20;
}

.menu-links .nav > li:hover > a:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #fff;
    top: 95%;
    position: absolute;
    left: 30px;
    transform: rotate(45deg);
    z-index: 11;
}
.menu-links .nav > li.no-sub:hover > a:after {
	content:none;
}
.menu-links .nav > li:hover > .sub-menu,
.menu-links .nav > li:hover > .mega-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.menu-links .nav > li > .sub-menu,
.menu-links .nav > li > .mega-menu {
	box-shadow:0 0 40px rgba(0, 0, 0, 0.2);
}
.menu-links .nav > li .sub-menu {
    background-color: #ffffff;
    display: block;
    left: 0;
    list-style: none;
    opacity: 0;
    padding: 15px 0;
    position: absolute;
    visibility: hidden;
    width: 220px;
    z-index: 10;
	border-radius: 0;
	top: 100%;
}
.menu-links .nav > li .sub-menu li {
    position: relative;
}
.menu-links .nav > li .sub-menu li a {
	color: #585e6d;
    display: block;
    font-size: 15px;
    padding: 8px 25px;
    text-transform: capitalize;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    position: relative;
    font-weight: 500;
}
.menu-links .nav > li .mega-menu li a span,
.menu-links .nav > li .sub-menu li a span{
	position:relative;
}
.menu-links .nav > li .mega-menu li a span:after,
.menu-links .nav > li .sub-menu li a span:after{
	content: "";
    position: absolute;
    height: 1px;
    width: 0;
    background: var(--primary);
    bottom: 0;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	-moz-transform-origin:right;
	-webkit-transform-origin:right;
	-ms-transform-origin:right;
	-o-transform-origin:right;
	transform-origin:right;
}
.menu-links .nav > li .mega-menu li:hover > a span:after,
.menu-links .nav > li .sub-menu li:hover > a span:after{
	width:100%;
	-moz-transform-origin:left;
	-webkit-transform-origin:left;
	-ms-transform-origin:left;
	-o-transform-origin:left;
	transform-origin:left;
	left:0;
	right:auto;
}
.menu-links .nav > li .sub-menu li:hover a {
    text-decoration: none;
}
.menu-links .nav > li .sub-menu li:hover > a {
     color: var(--primary);
}
.menu-links .nav > li .sub-menu li:last-child {
    border-bottom: 0px;
}
.menu-links .nav > li .sub-menu li > .sub-menu.left,
.menu-links .nav > li .sub-menu li:hover .sub-menu.left {
    left: auto;
    right: 220px;
}
.menu-links .nav > li > .sub-menu.left{
	left: auto;
    right: 0;
}
.menu-links .nav > li .sub-menu li .fa {
    color: inherit;
    display: block;
    float: right;
    font-size: 15px;
    position: absolute;
    right: 25px;
    top: 12px;
	opacity: 1;
}
.menu-links .nav > li .sub-menu li .fa.fa-nav {
	color: inherit;
    display: inline-block;
    float: none;
    font-size: 13px;
    margin-right: 5px;
    opacity: 1;
    position: unset;
    right: 10px;
    top: 12px;
}
.menu-links .nav > li .sub-menu li > .sub-menu {
    left: 220px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.menu-links .nav > li .sub-menu li:hover > .sub-menu {
    left: 220px;
    margin: 0px;
    opacity: 1;
    top: -1px;
    visibility: visible;
}
.menu-links .nav > li .sub-menu li:hover > .sub-menu:before {
    background-color: transparent;
    bottom: 0px;
    content: '';
    display: block;
    height: 100%;
    left: -6px;
    position: absolute;
    top: 0px;
    width: 6px;
}


/*=================================
	5. MEGA MENU
=================================*/
.menu-links .nav > li.has-mega-menu {
    position: inherit;
}
.menu-links .nav > li .mega-menu {
    background-color: #ffffff;
    display: table;
    left: 0px;
    list-style: none;
    opacity: 0;
    position: absolute;
    right: 0px;
    visibility: hidden;
    width: 100%;
    margin-top: 20px;
	z-index: 9;
	    padding: 0;
}
.menu-links .nav > li .mega-menu > li {
    display: table-cell;
    padding: 30px 0 25px;
    position: relative;
    vertical-align: top;
    width: 25%;
}
.menu-links .nav > li .mega-menu > li:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    width: 1px;
    height: 100%;
}
.menu-links .nav > li .mega-menu > li:last-child:after {
    display: none;
}
.menu-links .nav > li .mega-menu > li > a {
	color: #000;
    display: block;
    padding: 0 25px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.menu-links .nav > li .mega-menu > li ul {
    list-style: none;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    width: 100%;
}
.menu-links .nav > li .mega-menu > li ul a {
    color: #585e6d;
    display: block;
    font-size: 15px;
    line-height: 34px;
    text-transform: capitalize;
    padding: 0 25px;
}
.menu-links .nav > li .mega-menu > li ul a:hover {
    color: #EFBB20;
}
.menu-links .nav .mega-menu a i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px;
}
.menu-links .nav > li.menu-item-has-children:before {
    content: "\f078";
    display: block;
    font-family: "FontAwesome";
    right: 4px;
    position: absolute;
    top: 50%;
    color: #999;
    margin-top: -8px;
    font-size: 8px;
}
/* Menu */
.menu-links .nav > li.add-mega-menu .mega-menu,
.has-mega-menu.add-mega-menu .mega-menu{
	display: block;
	padding: 20px;
	width: 1170px;
	max-width: 1170px;
	margin: auto;
}
.menu-links .nav > li .add-menu{
    display: flex;
	width:420px;
	left:-60px;
}
.add-menu-left{
    width: 100%;
	padding-left: 25px;
	border-bottom:0 !important;
}
.add-menu-left ul{
	padding:0;
}

.add-menu-right{
    min-width: 240px;
    width: 240px;
    padding: 10px 20px;
}
.menu-links .nav > li.has-mega-menu.demos .mega-menu{
	left:auto;
	right:auto;
	max-width: 600px;
	z-index: 9;
}
.menu-links .nav .add-menu-left ul li{
	list-style:none;
}
.menu-links .nav .add-menu-left ul li a{
	padding: 8px 0px;
}
.menu-links .nav .add-menu-left ul li a:hover{
	background-color:rgba(0,0,0,0);
}
.menu-adv-title{
	font-size: 16px;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    position: relative;
}
.menu-adv-title:after{
	content: "";
	width: 25px;
	height: 2px;
	display: block;
	background: #000;
	position: absolute;
	bottom: 0;
	left: 0;
	
}
.menu-links .menu-logo,
.menu-links .nav-social-link{
	display:none;
}
.header .menu-links .nav > li.dashboard .sub-menu{

}
.header .menu-links .nav > li.dashboard a{
	
}
.header .menu-links .nav > li.dashboard a .icon {
	padding: 0;
    font-size: 18px;
    margin-right: 15px;
    color: var(--primary);
    text-shadow: -4px 10px 15px rgba(46,36,255,0.3);
    margin-left: 0;
    float: none;	
}
/* Header Extra Nav */
.secondary-inner > ul{
	list-style:none;
	padding:0;
	display: flex;
    align-items: center;
	margin:0;
}
.secondary-inner ul li{
	display:inline-block;
	position:relative;
	margin-left: 20px;
}
.secondary-inner i{
	margin:0;
}
.search-btn:after{
	left:0;
	right:auto;
	top: 5px;
}
/* Nav  Badge */
/* Nav  Badge */
.shop-cart.navbar-right{
	margin-right:0;
	float:none;
	display: inline-block;
}
.shop-cart .dropdown-menu.cart-list{
	box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
	top: 50px;
	border:0;
}
.shop-cart .badge{
	font-size: 9px;
    height: 15px;
    line-height: 15px;
    min-width: 15px;
    padding: 0;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;
}
.shop-cart  li{
	padding: 10px 20px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
	
}
.shop-cart  li:last-child{
	border-bottom:0;
}
.shop-cart .media-left a {
    display: block;
    width: 60px;
}
.shop-cart .site-button-link{
	position:relative;
}
.cart-list.dropdown-menu .title {
	color: #020d26;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 22px;
}
.cart-list.dropdown-menu{
	width: 300px;
    margin-top: 23px;
    right: 0;
    left: auto;
    border: 0;
    box-shadow: 0px 30px 60px 0 rgba(0,0,0,0.1);
}
.cart-list.dropdown-menu li{
	width:100%;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding: 15px 20px;
	margin: 0;
}
.cart-list.dropdown-menu li.cart-item .media .media-left {
    border: 1px solid rgba(0,0,0,0.05);
    padding: 5px;
}
.cart-list .media-object{
	width:50px;
}
.cart-item .media-body .price{
    color: var(--primary);
    font-weight: 700;
}
.cart-item .media-body span{
	font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 10px;
}
.cart-item .media-body{
	position:relative;
	padding-left: 15px;
	padding-right: 25px;
}
.item-close {
    color: #e86161;
	cursor:pointer;
}
.cart-list.dropdown-menu li.cart-item.subtotal .title span {
    float: right;
}
.cart-list.dropdown-menu li.cart-item .item-close {
	color: red;
    font-weight: 300;
    position: absolute;
    line-height: 24px;
    right: 0;
    top: 0;
    margin-right: 0;
    font-size: 35px;
}
@media only screen and (max-width: 1200px) {
	.secondary-inner ul li{
		margin-left: 15px;
	}
	.menu-links .nav > li > a{
		padding: 28px 5px;
		font-size: 16px;	
	}
}
@media only screen and (max-width: 767px) {
	.shop-cart .dropdown-menu.cart-list{
		left:auto;
		right:0;
	}
	.header .container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
	.cart-list.dropdown-menu{
		margin-top: 6px;
	}
}
@media only screen and (max-width: 991px) {
	[class*="ovbl-"] .counter-style-1 .counter-text, 
	.header-transparent .menu-links .nav > li:hover > a, 
	.header-transparent .menu-links .nav > li.active > a {
		color:#000 !important;
	}
	.add-menu-left ul{
		display: block !important;
	}
	.menu-links .menu-logo,
	.menu-links .nav-social-link{
		display:block;
	}
	.menu-links .nav-social-link{
		margin-top: auto;
		text-align: center;
		width: 100%;
		padding: 10px 0;
		background: #fff;	
	}
	.menu-links .nav-social-link a{
		color:#000;
		padding:5px 10px;
	}
	.menu-links .nav > li.has-mega-menu.demos .mega-menu{
		max-width:100%;
	}
	.menu-links .nav > li .mega-menu{
		border:0;
	}
	.menu-links .nav > li .mega-menu > li{
		padding:10px 0;
	}
	.menu-links .nav > li .sub-menu,
	.menu-links .nav > li .mega-menu {
		border-radius:0;
		border-width: 1px 0 1px 0;
	}
    .menu-links .nav i {
        margin-top: 6px;
		float: right;
    }
    .menu-links {
        clear: both;
        margin: 0 -15px;
        border-bottom: 1px solid #E9E9E9;
    }
    .menu-links .nav {
        float: none;
        background: #fff;
		width:100%;
		display: block;
		margin-bottom: auto;
    }
    .menu-links .nav li {
        float: none;
		display:block;
		width:100%;
    }
    .menu-links .nav > li .sub-menu > li,
    .menu-links .nav > li .mega-menu > li {
        float: none;
        display: block;
        width: auto;
    }
    .menu-links .nav > li > a {
        padding: 10px 15px;
        border-top: 1px dashed #E9E9E9;
		display:block;
    }
    .menu-links .nav > li > a:hover,
    .menu-links .nav > li > a:active,
    .menu-links .nav > li > a:focus {
        background-color: #f0f0f0;
        text-decoration: none;
    }
    .menu-links .nav > li .mega-menu > li:after {
        display: none;
    }
    .menu-links .nav > li ul,
    .menu-links .nav > li .sub-menu,
    .menu-links .nav > li .mega-menu {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
        background: transparent;
    }
    .menu-links .nav > li ul.mega-menu ul {
        display: none;
    }
    .menu-links .nav > li:hover > ul,
    .menu-links .nav > li:hover .sub-menu,
    .menu-links .nav > li:hover .mega-menu,
    .menu-links .nav > li .sub-menu li > .sub-menu {
        opacity: 1;
        visibility: visible;
        display: block;
        margin: 0;
    }
    .menu-links .nav > li ul.mega-menu li:hover ul {
        display: block;
    }
    .side-nav .nav.navbar-nav li a i.fa-chevron-down:before,
    .nav.navbar-nav li a i.fa-chevron-down:before {
		content:"\f078";
	}
	.side-nav .nav.navbar-nav li.open a i.fa-chevron-down:before,
	.nav.navbar-nav li.open a i.fa-chevron-down:before {
		content:"\f054";
	}
	.menu-links .nav > li .sub-menu li i.fa-angle-right:before{
		content: "\f078";
		font-size: 10px;
		position: absolute;
		z-index: 2;
		color: #000;
		right: 20px;
		top: -5px;
	}
	.menu-links .nav > li .sub-menu li.open i.fa-angle-right:before{
		content: "\f054";
	}
    .menu-links .nav > li .sub-menu .sub-menu,
    .menu-links .nav > li:hover .sub-menu .sub-menu,
    .menu-links .nav > li:hover .sub-menu,
    .menu-links .nav > li:hover .mega-menu {
        display: none;
        opacity: 1;
        margin-top: 0;
    }
	.menu-links .nav li .sub-menu .sub-menu{
		display: none;
        opacity: 1;
        margin-top: 0;
	}
	.menu-links .nav > li.open > .sub-menu .sub-menu{
		display: none;
	}
	.menu-links .nav > li.open > .sub-menu li.open .sub-menu,
    .menu-links .nav > li.open > .mega-menu,
    .menu-links .nav > li.open > .sub-menu,
	.menu-links .nav > li ul.mega-menu ul{
        display: block;
        opacity: 1;
        margin-top: 0;
		box-shadow: none;
    }
	.menu-links .nav > li:hover > a:after{
		content:none;
	}
	.menu-links .nav > li .sub-menu li .fa{
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		margin: 0;
		right: -1px;
		color:#000;
	}
	.menu-links .nav > li .mega-menu > li{
		padding:0;
	}
	.menu-links .nav > li .mega-menu > li > a{
		display:none;
	}
	.menu-links .nav .mega-menu a i{
		display:inline-block;
		float:none;
		margin-top: 0;
	}
    .menu-links .nav .open > a,
    .menu-links .nav .open > a:focus,
    .menu-links .nav .open > a:hover {
        background-color: inherit;
        border-color: #e9e9e9;
    }
	.menu-links .nav > li .sub-menu li a{
		padding: 8px 15px;
	}
	.add-menu-left{
		padding-left: 0;
	}
	.add-menu-right{
		padding: 10px 15px;
	}
	.menu-links .nav > li .mega-menu > li ul a{
		padding: 2px 15px;
	}
	.style-3 .navbar-toggler span{
		background:#fff;
	}
	.shop-cart .dropdown-menu.cart-list{
		left:auto;
		right:0;
	}
	.rs-nav .menu-links.nav-dark{
		background-color:#202020;
	}
	.rs-nav .menu-links{
		position: fixed;
		width: 60px;
		left:-280px;
		height:100vh !important;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-moz-transition:all 0.5s;
		-o-transition:all 0.5s;
		top:0;
		background-color:#fff;
		margin:0;
		z-index:99;
		overflow-y: scroll;
		flex-direction: column;
	}
	.rs-nav .menu-links li.open a{
		position:relative;
	}
	.navbar-nav{
		height:auto;
	}
	.rs-nav .menu-links.show {
		left: -1px;
		transition:all 0.8s;
		-webkit-transition:all 0.8s;
		-moz-transition:all 0.8s;
		-o-transition:all 0.8s;
		margin:0;
		width: 100%;
		width: 300px;
		padding: 15px 15px 5px 15px;
	}
	.rs-nav .sticky-header.active .menu-links .nav{
		height:auto;
	}
	.rs-nav .navbar-toggler.open:after{
		background-color: rgba(0, 0, 0, 0.6);
		content: "";
		height: 100%;
		left: 0;
		position: fixed;
		right: 0px;
		top: -20px;
		transform: scale(100);
		-o-transform: scale(100);
		-moz-transform: scale(100);
		-webkit-transform: scale(100);
		width: 100%;
		z-index: -1;
		transition: all 0.5s;
		transform-origin: top right;
		margin: 0 0px 0px 10px;
		box-shadow: 0 0 0 500px rgba(0,0,0,0.6);
	}
	.rs-nav .menu-links .menu-logo {
		display: block;
		float: none;
		height: auto;
		max-width: 100%;
		padding: 20px 15px;
		width: 100%;
		text-align:center;
	}
	.rs-nav .menu-links .menu-logo img{
		max-width: unset;
		width: 130px;
		vertical-align: middle;
	}
	.rs-nav .menu-links .menu-logo a{
		display:inline-block;
	}
	.rs-nav .navbar-toggler.open span{
		background:#fff;
	}
	.menu-links .nav > li > .mega-menu,
	.menu-links .nav > li > .sub-menu{
		border-top: 1px dashed rgba(0,0,0,0.1);
		border-top: 1px dashed rgba(0,0,0,0.1);
		
	}
	.menu-links .nav > li .mega-menu{
		padding: 15px 0;
	}
}
.sticky-no .menu-bar {
    position: static !important;
}
.sticky-header.active .menu-bar {
    position: fixed;
    top: 0;
    left: 0;
	box-shadow:0 0 50px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 991px) {
    .sticky-header.active .menu-links .nav > li > a {
        padding: 10px 15px;
    }
}
@media only screen and (max-width: 767px) {
    .sticky-header.active .menu-links .nav {
        height: 225px;
    }
    .sticky-header.active .menu-links .nav > li > a {
        padding: 10px 15px;
    }
}

/* Extra Info */
.header-extra-info{
    padding: 20px 0;
	width: 100%;	
}
.header-contant-info ul{
	margin:0;
	padding:0;
	list-style:none;
    display: flex;
    align-items: center;	
}
.header-contant-info ul li{
    display: inline-block;
    padding-left: 60px;
    position: relative;
    padding: 5px 20px 0 60px;
    margin-right: 20px;
}
.header-contant-info ul li:first-child{
	border-right: 1px solid rgba(0,0,0,0.2);
}
.header-contant-info ul li:last-child{
	padding:0;
	margin-right:0;
}
.header-contant-info ul li .icon{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid var(--primary);
    color: var(--primary);
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    position: absolute;
    left: 0;
    top: 0;	
}
.header-contant-info ul li .title{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    line-height: 20px;
}
.header-contant-info ul li p{
    color: #6d7380;
    margin: 0;
    font-size: 15px;
}
.extra-logo{
	max-width:210px;
}
.extra-info-inner{
	display: flex;
    justify-content: space-between;
    align-items: center;	
}


@media only screen and (max-width: 991px) {
	.header-transparent .menu-links .nav > li > a{
		color:#000;
	}
	.header-transparent .menu-links.nav-dark .nav > li > a{
		color:#fff;
	}
	.style-3.box .navbar-toggler{
		margin: 28px 0px 30px 15px;
	}
	.style-3.box .sticky-header{
		position: unset;
		width: 100%;
		height: auto;
		background-color: var(--primary);
	}
	.style-3.box {
		margin-bottom: 0;
	}
	.style-2 .sticky-header {
		position: relative;
	}
	.style-2 .menu-bar {
		background:  var(--primary);
	}
	 .style-2 .menu-bar:before{
		height:100%;
		width:25%;
		position:absolute;
		top:0;
		right:0;
		content:"";
		background:#fff;
	}
	.style-2 .secondary-menu {
		padding: 17px 0px 17px 0;
	}
}
@media only screen and (max-width: 767px) {
	.style-2 .secondary-menu{
		padding: 7px 0px 7px 0;
	}
	.fullwidth .container-fluid{
		padding-left: 15px;
		padding-right: 15px;
	}
	.search-box form .form-control {
		height: 50px;
	    font-size: 14px;	
		width: 120px;
	    padding: 0px 15px 0px 40px;		
	}
	.search-box form{
		height: 50px;
		font-size: 14px;	
	}
	.search-box form button	{
		top: 14px;
		font-size: 18px;
		padding: 0 0 0 15px;	
	}
	.style-3.box .secondary-inner ul li {
		margin-left: 10px;
		margin-right: 0;
	}
	.style-3.box .navbar-toggler {
		margin: 14px 0px 14px 15px;
	}

}
