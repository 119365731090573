@use './variables';

body {
  background-color: $body-color;
  font: 100% $font-stack;
  color: $primary-font-color;
  font-size: $body-font-size;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
  height: 100%;
}

img {
  max-width: 100%;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $black-100;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



a {
  @extend %links;
}

a.hover {
  @extend %links;
  text-decoration: underline;
}

button, .btn {
  font-weight: 600;
  letter-spacing: 0.05em;
}

/*=================  
	Headings
====================*/
h1, h2, h3, h4, h5, h6 { color: $black-100; font-family: $font-stack; margin-top:0; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: inherit; }
h1 {font-size: 60px; line-height: 1.3; margin-bottom: 25px; font-weight: 700; }
h2 {font-size: 48px; line-height: 1.25; margin-bottom: 25px; font-weight: 600; }
h3 {font-size: 35px; line-height: 1.3; margin-bottom: 20px; font-weight: 800; }
h4 {font-size: 28px; line-height: 1.3; margin-bottom: 15px; font-weight: 600; }
h5 {font-size: 21px; line-height: 1.3; margin-bottom: 10px; font-weight: 600; }
h6 {font-size: 18px; line-height: 1.4; margin-bottom: 10px; font-weight: 600; }
@media only screen and (max-width: 767px) {
	h1{font-size: 30px; line-height: 40px; margin-bottom: 20px; font-weight: 700; }
	h2{font-size: 26px; line-height: 35px; margin-bottom: 20px; font-weight: 700; }
	h3{font-size: 24px; line-height: 30px; margin-bottom: 15px; font-weight: 700; }
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

.bg-primary {
  background-color: $red-200 !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
   .text-sm-left {
    text-align: left !important;
   }
   .text-sm-center {
    text-align: center !important;
   }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .w-md-75 {
      width: 75%;
  }
  .text-md-left {
    text-align: left !important;
   }
   .text-md-center {
    text-align: center !important;
   }
}
