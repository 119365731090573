* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif; // 2
    line-height: 1.15; // 3
    -webkit-text-size-adjust: 100%; // 4
    -webkit-tap-highlight-color: rgba(black, 0); // 5
  }
  
  :after, :before {
    box-sizing: border-box;
  }